import React from 'react';

import Hero, { HeroWhyMyfembreeUFContent } from '../../components/Hero/Hero';
import Layout from '../../components/Layout';
import WhatToExpect from '../../components/WhatToExpect/WhatToExpect';
import { accordionUFProps } from '../../components/Accordion/Accordion';
import QuickLinks2, {
  QuickLinks2WhyMyfembreeUFContent
} from '../../components/QuickLinks2/QuickLinks2';
import IconTextGrid, {
  whatIsMyfembreeUF
} from '../../components/IconTextGrid/IconTextGrid';
import CostSupport, {
  goodNewsUF
} from '../../components/CostSupport/CostSupport';
import FloatingLinks, {
  WhyMyfembreeUFFloatingLinkData
} from '../../components/FloatingLinks/FloatingLinks';
import KnockoutDriver, {
  startConvoUFProps
} from '../../components/KnockoutDriver/KnockoutDriver';
import HowDoesMfbWork, {
  howDoesMfbWorkUFContent
} from '../../components/HowDoesMfbWork/HowDoesMfbWork';

import Shield from '../../assets/svgs/Shield.svg';
import SideEffects, {
  sideEffectsUF
} from '../../components/SideEffects/SideEffects';
import BenefitsUF from '../../components/BenifitsUF/BenefitsUF';
import UfWhyMyfembreeHero from '../../components/uf-why-myfembree/uf-why-myfembree-hero/uf-why-myfembree-hero';
import UfWhyMyFembreeMakes from '../../components/uf-why-myfembree/uf-makes/uf-makes';
import UfWhatAreTheBenefits from '../../components/uf-why-myfembree/uf-what-are-the-benefits/uf-what-are-the-benefits';
import UfWhatCouldYouExpect from '../../components/uf-why-myfembree/uf-what-could-you-expect/uf-what-could-you-expect';
import UfWhatCauses from '../../components/uf-why-myfembree/uf-what-causes/uf-what-causes';
import UfHowDoesMyfembreeWork from '../../components/uf-why-myfembree/uf-how-does-myfembree-work/uf-how-does-myfembree-work';
import UfPossibleSideEffects from '../../components/uf-why-myfembree/uf-possible-side-effects/uf-possible-sidde-effects';
import UfMakeAnAppoitment from '../../components/uf-why-myfembree/uf-make-an-appointment/uf-make-an-appointment';

const Home = () => {
  const {
    refs: [
      headerRef,
      whatIsRef,
      benefitsRef,
      whatToExpectRef,
      sideEffectsRef,
      howDoesTtRef
    ],
    floatingLinksProps
  } = WhyMyfembreeUFFloatingLinkData();
  return (
    <Layout section="UF" page="/uterine-fibroids/why-myfembree/">
      <>
        <UfWhyMyfembreeHero />
        <UfWhyMyFembreeMakes />
        <UfWhatAreTheBenefits />
        <UfWhatCouldYouExpect />
        <UfWhatCauses/>
        <UfHowDoesMyfembreeWork/>
        <UfPossibleSideEffects/>
        <UfMakeAnAppoitment/>
        {/* <Hero {...{ ...HeroWhyMyfembreeUFContent, floatingRef: headerRef }}>
          <QuickLinks2 {...QuickLinks2WhyMyfembreeUFContent} />
        </Hero> */}
        {/* <FloatingLinks {...floatingLinksProps} />
        <CostSupport {...goodNewsUF} />
        <a id="what-is-myfembree" className="relative -top-24 md:-top-44" />
        <IconTextGrid {...{ ...whatIsMyfembreeUF, floatingRef: whatIsRef }} />
        <BenefitsUF floatingRef={benefitsRef} />
        <WhatToExpect
          {...{
            accordions: [
              {
                ...accordionUFProps,
                collapsible: false,
                showButton: false,
                showFootnotes: true,
                open: true
              }
            ],
            floatingRef: whatToExpectRef
          }}
        />
        <SideEffects
          {...{
            ...sideEffectsUF,
            floatingRef: sideEffectsRef
          }}
        />
        <KnockoutDriver {...startConvoUFProps} />
        <HowDoesMfbWork
          {...{ ...howDoesMfbWorkUFContent, floatingRef: howDoesTtRef }}
        /> */}
        {/* <KnockoutDriver
          {...{
            icon: <Shield />,
            iconStroke: 'white',
            iconBgColor: 'dark-green',
            prompt:
              'There are ways to potentially save on the cost of Myfembree.',
            buttonProps: {
              label: 'FIND OUT HOW',
              ariaLabel: '',
              color: 'secondary-fuchsia',
              target: '_self',
              href: '/uterine-fibroids/cost-and-support/',
              position: 'right'
            },
            color: 'purple-100'
          }}
        /> */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL =
    'https://www.myfembree.com/uterine-fibroids/why-myfembree/';
  const title =
    'Why MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets for treatment of heavy menstrual bleeding for Uterine Fibroids';
  const pageTitle =
    'Why MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets for treatment of heavy menstrual bleeding for Uterine Fibroids';
  const description =
    'Explore MYFEMBREE® once daily tablets as a potential treatment option that may help reduce heavy menstrual bleeding due to UF. See safety info & BOXED WARNING.';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
    </>
  );
};

export default Home;
