import React from 'react';

// import SittingIcon from '../../../assets/images/sitting-icon.svg';
// import SleepingIcon from '../../../assets/images/sleeping-icon.svg';
// import ParticipatingIcon from '../../../assets/images/participating-icon.svg';
import PeriodCycleIcon from '../../../assets/images/uf-why/cycle-1.png';
import Porcentage50Icon from '../../../assets/images/uf-why/porcentage-50.png';
function UfWhatCouldYouExpect() {
  return (
    <section>
      <div className="container mx-auto">
        <div className="uf-what-could flex flex-col text-white-100 items-center md:items-center">
          <div>
               <h2 className="font-mulish font-bold text-center lg:pl-5">
            What could you expect when taking Myfembree?
          </h2>
          <div className=" flex flex-col md:flex-row items-center md:items-start">

            <div className="icon-container flex justify-center  mb-5 ">
              <img src={ PeriodCycleIcon } alt="Period Cycle Icon" width={97} height={100}/>
            </div>
  
            <div className="flex  flex-col items-center md:items-start">
              <p className="text-center md:text-start md:pb-3 ">
                Your next period could be lighter. Within 1 cycle, some women
                had less period bleeding.
              </p>

              <p className=" footnote-without text-center md:text-start px-2 md:px-0">
              While the studies were not specifically designed to determine how quickly Myfembree worked, some of the women started to see a reduction in bleeding at Week 4.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center md:items-start">
            <div className="icon-container flex justify-center  mb-5">
            <img className='md:me-1 md:mt-3' src={ Porcentage50Icon } alt="50% Icon" width={104} height={103}/>

            </div>

            <div className="flex flex-col items-center md:items-start">
              <p className="text-center md:text-start md:pt-4">
              Half of women stopped getting their periods during the last month of treatment in the studies.<sup>§</sup> A majority of women saw their periods return once they stopped the therapy,<sup>‖</sup> typically within 1-2 months.
              </p>
              <p className="footnote text-center md:text-start md:px-0">
                <sup>§</sup>Compared with 5% of women on placebo.

              </p>
              <p className="footnote text-center md:text-start px-1  md:px-0">
                <sup>‖</sup>Based on 65 women who did not enter a post-treatment extension study or stopped treatment earlier.
              </p>
            </div>
          </div>
          </div>
       
        </div>
      </div>
    </section>
  );
}

export default UfWhatCouldYouExpect;
